
import React, { useState } from 'react';
import TodoForm from './TodoForm';
import { CiEdit } from 'react-icons/ci';
import { IoAlarmOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReminderForm from './ReminderForm'; // Import your reminder form component


const Todo = ({ todo, completeTodo, removeTodo, updateTodo }) => {
  const [edit, setEdit] = useState({
    id: null,
    value: ''
  });
  const [showReminderForm, setShowReminderForm] = useState(false); // State to control the visibility of the reminder form

  const submitUpdate = value => {
    updateTodo(todo.id, value);
    setEdit({
      id: null,
      value: ''
    });
  };

  const handleReminderClick = () => {
    // Check if the todo is complete before showing the reminder form
    if (!todo.isComplete) {
      setShowReminderForm(true);
    }
  };

  const handleReminderClose = () => {
    setShowReminderForm(false);
  };

  if (edit.id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />;
  }

  return (
    <div className={todo.isComplete ? 'todo-row complete' : 'todo-row'}>
      <div className="todo-text" onClick={() => completeTodo(todo.id)}>
        {todo.text}
      </div>
      
            <div className='icons'>
              <RiDeleteBin6Line onClick={() => removeTodo(todo.id)} className='delete-icon' />
              <CiEdit onClick={() => setEdit({ id: todo.id, value: todo.text })} className='edit-icon' />
              <IoAlarmOutline onClick={handleReminderClick} className='reminder-icon' />
            </div>
      {showReminderForm && (
        <ReminderForm
          todo={todo}
          onClose={handleReminderClose}
          // Pass any necessary props to the reminder form component
        />
        )}
    </div>
  );
}

export default Todo;
