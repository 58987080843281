// ToggleSwitch.js
import React from 'react';
import './ToggleSwitch.css'; // Import the CSS for the switch button

const ToggleSwitch = ({ isOn, handleToggle }) => {
  return (
    <label className="switch">
      <input 
        type="checkbox" 
        checked={isOn} 
        onChange={handleToggle} 
      />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleSwitch;
