import React, { useState, useEffect } from 'react';
import BackButton from './BackButton';
import { RiDeleteBin6Line, RiPrinterLine } from 'react-icons/ri'; // Importing print icon
import './MyLists.css'; // Adjust the path as necessary
import CookieInfoModal from './CookieInfoModal'; // Import the CookieInfoModal component
import { AiOutlineWarning } from 'react-icons/ai'; // Import AiOutlineWarning icon


function MyLists() {
  const [savedLists, setSavedLists] = useState([]);
  const [expandedList, setExpandedList] = useState(null); // Track the expanded list
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  useEffect(() => {
    const lists = JSON.parse(localStorage.getItem('savedTodoLists') || '[]');
    lists.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setSavedLists(lists);
  }, []);

  const toggleList = (id) => {
    setExpandedList(expandedList === id ? null : id);
  };

  // Removed removeItemFromList function since it's no longer needed

  const deleteList = (listIndex) => {
    // Confirm before removing
    const confirmDelete = window.confirm("Are you sure you want to delete this saved list?");
    if (confirmDelete) {
      const updatedLists = savedLists.filter((_, index) => index !== listIndex);
      setSavedLists(updatedLists);
      localStorage.setItem('savedTodoLists', JSON.stringify(updatedLists));
    }
  };
  
  const printList = (listIndex) => {
    const listToPrint = savedLists[listIndex];
    // Create an invisible iframe
    let iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    
    // Append it to the body (optional, for browsers that require the iframe to be in the document)
    document.body.appendChild(iframe);
    
    // Prepare the content to print
    const printContent = `
      <html>
        <head>
          <title>Print List</title>
          <style>
            body { font-family: Arial, sans-serif; }
            ul { list-style-type: disc; margin-left: 20px; }
            li { word-wrap: break-word; }
          </style>
        </head>
        <body>
          <h1>List saved on: ${new Date(listToPrint.timestamp).toLocaleDateString()}</h1>
          <ul>
            ${listToPrint.todos.map(todo => `<li>${todo.text}</li>`).join('')}
          </ul>
        </body>
      </html>
    `;
    
    // Write the content to the iframe
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(printContent);
    iframe.contentWindow.document.close();
    
    // Print the iframe's content
    iframe.contentWindow.focus(); // Focus the iframe's window
    iframe.contentWindow.print(); // Open the print dialog
    
    // Remove the iframe from the document after printing
    setTimeout(() => document.body.removeChild(iframe), 1000); // Delay to ensure print dialog opens
  };
  
  

  return (
    <div className='container'>
      <div className='lists-header'>
        <BackButton />
        <h1>My Saved Lists <button onClick={toggleModal} className="warning-icon-button"  aria-label="Warning about cookie data">
      <AiOutlineWarning />
</button></h1>
      </div>
      <CookieInfoModal isOpen={isModalOpen} onClose={toggleModal} />

      
      {savedLists.map((list, listIndex) => (
        <div key={listIndex} className="saved-list">
          <button onClick={() => toggleList(listIndex)} className="list-toggle">
            List saved on: {new Date(list.timestamp).toLocaleDateString()}
          </button>
          <RiDeleteBin6Line 
            onClick={() => deleteList(listIndex)} 
            className="delete-list-icon"
          />
          <RiPrinterLine 
            onClick={() => printList(listIndex)}
            className="print-list-icon"
          />
          {expandedList === listIndex && (
            <div className="todo-list">
              {list.todos.map((todo, todoIndex) => (
                <div key={todoIndex} className="todo-item">
                  {todo.text}
                  {/* Removed delete button for each todo item */}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default MyLists;