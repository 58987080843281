import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid'; // You need to install uuid package

function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : '');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [addButtonText, setAddButtonText] = useState("Hit Me with Tasks!");
  

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = e => {
    setInput(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    // Using uuid for unique id generation
    props.onSubmit({
      id: uuidv4(),
      text: input.trim() // Trim input to remove whitespace
    });
    setInput('');
  };


  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setAddButtonText("Add");
    } else {
      setAddButtonText("Hit Me with Tasks!");
    }
  }, [windowWidth]);




  return (
    <form onSubmit={handleSubmit} className='todo-form'>
      {props.edit ? (
        <>
          <input
            placeholder='Update your item'
            value={input}
            onChange={handleChange}
            name='text'
            ref={inputRef}
            className='todo-input edit'
            aria-label='Update your todo' // Added for accessibility
          />
          <button onClick={handleSubmit} className='todo-button edit' type='submit'>
            Update
          </button>
        </>
      ) : (
        <>
          <input
            placeholder='Add a todo'
            value={input}
            onChange={handleChange}
            name='text'
            className='todo-input'
            ref={inputRef}
            aria-label='Add a new todo' // Added for accessibility
          />
           <button onClick={handleSubmit} className='todo-button' type='submit'>
          {addButtonText}
        </button>
      </>
      )}
    </form>
  );
}

export default TodoForm;
